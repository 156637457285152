import Vue from "vue";
import store from '@/store';  //直接引用vuex中的store內容

// axios 設定
import axios from 'axios'
import VueAxios from 'vue-axios'
import qs from "qs";



Vue.use(VueAxios, axios)

axios.defaults.baseURL = store.state.base_url

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  store.commit("setLoading", true);
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  store.commit("setLoading", false);
    return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

var api = {
  //get data
  get(database) {
    // axios.post("general/edit/" + store.state.databaseName + "/" + database, qs.stringify(data.postData))
    // store.commit("setLoading", true); //讀取畫面on
    return new Promise((resolve, reject) => {
      axios.post(`general/getAll/${store.state.databaseName}/${database}`)
        .then((rs) => {
          // store.commit("setLoading", false); //讀取畫面off
          resolve(rs.data);
        })
        .catch((err) => {
          // store.commit("setLoading", false); //讀取畫面off
          reject(err);
        });
    });
  },

  //add data
  add(database, data) {
    // store.commit("setLoading", true); //讀取畫面on
    return new Promise((resolve, reject) => {
      axios.post(`general/add/${store.state.databaseName}/${database}`, qs.stringify(data))
        .then((rs) => {
          // store.commit("setLoading", false); //讀取畫面off
          resolve(rs.data);
        })
        .catch((err) => {
          // store.commit("setLoading", false); //讀取畫面off
          reject(err);
        });
    });
  },



  //post data (edit , update)
  post(database, data) {
    // store.commit("setLoading", true); //讀取畫面on
    return new Promise((resolve, reject) => {
      axios.post(`general/edit/${store.state.databaseName}/${database}`, qs.stringify(data))
        .then((rs) => {
          // store.commit("setLoading", false); //讀取畫面off
          resolve(rs.data);
        })
        .catch((err) => {
          // store.commit("setLoading", false); //讀取畫面off
          reject(err);
        });
    });
  },

  //delete data
  delete(database, data = null) {
    // store.commit("setLoading", true); //讀取畫面on
    return new Promise((resolve, reject) => {
      axios.post(`general/delv3/${store.state.databaseName}/${database}`, qs.stringify(data))
        .then((rs) => {
          // store.commit("setLoading", false); //讀取畫面off
          resolve(rs.data);
        })
        .catch((err) => {
          // store.commit("setLoading", false); //讀取畫面off
          reject(err);
        });
    });
  },

  //特別處理 url自定義
  options(url, data = null) {
    return new Promise((resolve, reject) => {
      axios.post(`${url}`, qs.stringify(data))
        .then((rs) => {
          resolve(rs.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  //特別處理 url自定義 + 下載檔案
  download(url, data = null) {
    // store.commit("setLoading", true); //讀取畫面on
    return new Promise((resolve, reject) => {
      axios.post(`${url}`, qs.stringify(data), {responseType : 'blob'})
        .then((rs) => {
          // store.commit("setLoading", false); //讀取畫面off
          resolve(rs.data);
        })
        .catch((err) => {
          // store.commit("setLoading", false); //讀取畫面off
          reject(err);
        });
    });
  }
}

export default api;