import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //未登入前最後url
    lastUrl:null,
    //樣式暫存區
    colorSet:{
      cardColor: '#F2F2F2',
      alertColor: '#245CA6'
    },
    verMsg: "2024.6.15.1",  //版本說明
    databaseName: "pddtvgame2024", //登入廠商代號及讀取的資料庫名
    logined: false, //登入情況->初設值為false
    loading: false, //讀取介面顯示用,介面放在App.vue裡
    // base_url: "http://127.0.0.1/pddtvgame2024api", //Localhost指定去讀圖片或其他東西的位置
    base_url: "https://www.pddtvgame.com/pddtvgame2024api", //線上專用指定去讀圖片或其他東西的位置

    //暫存用資料
    cData: {}, //使用廠商資訊
    pData: {},  //目前登入的使用者
    usingData: [], //目前使用中資料暫存
    newsItems:[],  //最新消息暫存
    eventItems:[], //學術資料暫存
    
    preorderItems:[], //預購遊戲資訊
    youtubeplaylistItems:[], //youtube playlist 顯片列表暫存
    usedIndexs:[], //暫存目前已播放的index值

    linkData: {  //連結用資料 同步使用於各畫面的selectList畫面
      allGameKindItems: [
        { text: "全部", class: 0 },
        {text:'PS5軟體', class: 1}, 
        {text:'PS5主機', class: 2},
        {text:'PS5週邊', class: 3},
        {text:'NS軟體', class: 4}, 
        {text:'NS主機', class: 5}, 
        {text:'NS週邊', class: 6}, 
        {text:'PS4軟體', class: 7}, 
        {text:'PS3軟體', class: 8}, 
        {text:'PS3週邊', class: 9}, 
        {text:'XONE軟體', class: 10}, 
        {text:'XB360軟體', class: 11}, 
        {text:'N3DS軟體', class: 12}, 
        {text:'NDS軟體', class: 13}, 
        {text:'PSP Vita軟體', class: 14}, 
        {text:'Wii U軟體', class: 15}, 
        {text:'Wii軟體', class: 16}, 
        {text:'NDS主機', class: 17}, 
        {text:'其他', class: 18}, 
      ],
      gameKindItems: [
        { text: "全部", class: 0 },
        { text: "PS5", class: 1 },
        { text: "PS4", class: 2 },
        { text: "NS", class: 3 },
        { text: "其他", class: 4 },
      ],
      newsItems: [
        { text: "全部", class: 0 },
        // { text: "學會公告", class: 1 },
        // { text: "活動訊息",class: 2 },
        // { text: "甄審相關訊息", class: 4 },
        // { text: "技術師相關訊息", class: 2 },
        // { text: "專科醫師甄審相關訊息", class: 5 },
      ],
      aboutItems: [
        { text: "理事長的話", class: 0 },
        { text: "學會簡介", class: 1 },
        { text: "組織章程", class: 2 },
        { text: "學會組織名單", class: 3 },
      ],
      eventItems: [
        // { text: "行事曆", class: 0 },
        { text: "學會學術活動", class: 0 },
        { text: "國內學術活動", class: 1 },
        { text: "國外學術活動", class: 2 },
        // { text: "其他學術活動", class: 3 },
        // { text: "線上積分申請" },
        // { text: "線上摘要投稿" },
      ],
      dataItems: [
        { text: "胃食道逆流症", class: 0 },
        { text: "食道蠕動異常疾病", class: 1 },
        { text: "功能性胃十二指腸疾病", class: 2 },
        { text: "功能性大腸疾病", class: 3 },
        { text: "其它", class: 4 },
      ],
      healthinsuranceItems: [
        { text: "健保規範專區", class: 0 },
      ],
      // colonoscopyItems: [
      //   { text: "影音區", class: 0 },
      //   { text: "指引", class: 1 },
      //   { text: "品質現況", class: 2 },
      //   { text: "其他", class: 3 },
      // ],
      elearnItems: [
        { text: "線上影音課程", class: 0 }, 
        { text: "線上講義資料", class: 1 }
      ],
      healthItems:[
        { text: "衛教專區", class: 0 },
      ],
      activitiesItems:[
        { text: "活動剪影", class: 0 },
      ],
      weblinkItems:[
        { text: "健康資訊網", class: 0 },
        { text: "國內相關醫療學術網", class: 1 },
        { text: "國外相關醫療學術網", class: 2 },
        // { text: "國內相關網站", class: 0 },
        // { text: "國外相關網站", class: 1 },
      ],
      humanItems: [
        { text: "人力資源", class: 0 }, 
      ],
    }

  },
  getters: {
  },
  mutations: {
    setStateVal(state,val){ //設定state中的某個(val.key)的值(val.val)
      state[val.key] = val.val
    },
    logined(state, val) {  //切換登入
      state.logined = val
    },
    setLoading(state, val) {  //設定讀取畫面On/Off
      state.loading = val
    },
    setcData(state, val) {  //設定登廠商資料
      state.cData = val
    },
    setpData(state, val) {  //設定登入者資料
      state.pData = val
    },
    setUsingData(state, val){  //設定使用中資料暫存
      state.usingData = val
    },
    setItems(state, val){  //設定指定資料到暫存區val.database是資料庫名,data放要在的資料
      state[val.database+'Items'] = val.data
    },
    setVal(state, data){ //設定state 裡的值,透過key value的方式
      
      state[data.key] = data.value
    },
  },
  actions: {
  },
  modules: {
  }
})
